<template>
	<ul class="main__list" v-if="!type">
		<li
			class="main__item"
			:class="className"
			v-for="(item, index) in data"
			:key="index"
		>
			{{ item }}
		</li>
	</ul>
	<ol class="main__list main__list--numeric" v-else>
		<li
			class="main__item"
			:class="className"
			v-for="(item, index) in data"
			:key="index"
		>
			{{ item }}
		</li>
	</ol>
</template>

<script>
export default {
	name: 'MainList',

	props: ['data', 'className', 'type'],
}
</script>
